import ru from '~/localization/ru.json';
import en from '~/localization/en.json';
import { pluralRuleRU } from '~/localization/pluralRules';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: process.env.MARKET_TARGET === 'INTERNATIONAL' ? 'en' : 'ru',
  fallbackLocale: 'ru',
  pluralRules: {
    ru: pluralRuleRU,
  },
  messages: {
    ru,
    en,
  },
}));
